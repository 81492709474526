import {Card, ConfigProvider, Layout, Spin, Typography} from 'antd';
import EnrollmentForm                                          from './EnrollmentForm';
import React, {useEffect}                                          from 'react';
import {useGetWorkflowConfigQuery}            from './api/enrollement';
import {selectToken} from './store/authSlice';
import {useSelector}                          from 'react-redux';
import {DefaultUIConfiguration}                                from './types/UIConfiguration';
import {Content, Footer, Header}                               from 'antd/lib/layout/layout';
import {PoweredBy} from './icons';

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 36,
  lineHeight: '64px',
  backgroundColor: '#e46e28'
}

function startOver() {
  sessionStorage.removeItem('enrollmentId')
  window.location.reload()
}

const EnrollmentUI = () => {
  const token = useSelector(selectToken)
  const { data, isLoading, refetch, error} = useGetWorkflowConfigQuery({}, {
    skip: !token
  })

  useEffect(() => {
    if (token) {
      refetch()
    }
  }, [token])

  // todo - apply document title if config contains
  // useEffect(() => {
  //   if (data) {
  //     document.title = ''
  //   }
  // }, [isLoading])

  return (
    <>
      <Spin spinning={isLoading} fullscreen data-testid='spinner'/>
      {
        (data) && (
          <ConfigProvider theme={{
            token: {
              colorText: data.color?.fontColor || DefaultUIConfiguration.uiColorSchema.fontColor,
              colorTextLabel: data.color?.fontColor || DefaultUIConfiguration.uiColorSchema.fontHeaderColor
            }
          }}>
            <Layout style={{ backgroundColor: data.color?.backgroundColor || DefaultUIConfiguration.uiColorSchema.backgroundColor, minHeight: '100vh' }}>
              <Header style={{...headerStyle, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                ...(data.color?.fontHeaderColor) ? { color: data.color.fontHeaderColor } : {} }}>
                <Typography.Link onClick={() => startOver()}
                                 style={{color: data.color?.fontColor || DefaultUIConfiguration.uiColorSchema.fontHeaderColor}}>
                  Start Over</Typography.Link>
                <Typography.Text>Powered by Ruckus</Typography.Text>
              </Header>
              <Content>
                <Card style={{ height: '100%', padding: 8 }}>
                  <EnrollmentForm
                    uiColorConfig={{ ...DefaultUIConfiguration.uiColorSchema, ...data.color }}
                    uiStyleConfig={{ ...DefaultUIConfiguration.uiStyleSchema, ...data.styles }}
                  />
                </Card>
              </Content>
              <Footer style={{ display: 'flex', justifyContent: 'end' }}>
                <PoweredBy/>
              </Footer>
            </Layout>
          </ConfigProvider>
        )
      }
    </>
  )
}

export default EnrollmentUI
