import {RcFile} from 'antd/lib/upload';

export interface UIColorSchema {
  fontHeaderColor: string
  fontColor: string
  backgroundColor: string
  buttonColor: string
  buttonFontColor: string
}

export interface UIStyleSchema {
  logoRatio: number
  titleFontSize: number
  logoImageFileName?: string
  backgroundImageName?: string
}

export interface UIConfiguration {
  wifi4EUNetworkId?: string
  disablePoweredBy: boolean
  logoImage?: string
  logoFile?: RcFile
  backgroundImage?: string
  backgroundImageFile?: RcFile
  uiColorSchema: UIColorSchema
  uiStyleSchema: UIStyleSchema
  welcomeTitle: string
  welcomeName: string
}

export const DefaultUIConfiguration : UIConfiguration = {
  disablePoweredBy: false,
  uiColorSchema: {
    fontHeaderColor: 'var(--acx-neutrals-100)',
    backgroundColor: 'var(--acx-primary-white)',
    fontColor: 'var(--acx-neutrals-100)',

    buttonFontColor: 'var(--acx-primary-white)',
    buttonColor: 'var(--acx-accents-orange-50)'
  },
  uiStyleSchema: {
    logoRatio: 1,
    titleFontSize: 16
  },
  welcomeName: '',
  welcomeTitle: ''
}
