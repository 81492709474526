import {FormInstance} from 'antd';
import React from 'react';

interface OnChange {
  (disableContinue: boolean): void;
}

interface NetworkConfig {
  ssid: string;
  password: string;
}

interface DeviceConfig {
  networkConfigs: NetworkConfig[];
}

// create a sample device configuration
export const sampleDeviceConfig: DeviceConfig = {
  networkConfigs: [
    {
      ssid: 'MyNetwork',
      password: 'SuperSecretPassword'
    },
    {
      ssid: 'GuestNetwork',
      password: 'GuestPassword123'
    }
  ]
}

export type {OnChange, DeviceConfig};

export interface ActionProps<T> {
  config: T,
  form: FormInstance,
  setDisableContinue: React.Dispatch<React.SetStateAction<boolean>>
}

export const browserSupportedLocales: Record<string, string> = {
  'en-US': 'en-US',
  'en': 'en-US',
  'es': 'es-ES',
  'es-ES': 'es-ES',
  'ja': 'ja-JP',
  'ja-JP': 'ja-JP',
  'fr': 'fr-FR',
  'fr-FR': 'fr-FR',
  'ko': 'ko-KR',
  'ko-KR': 'ko-KR',
  'pt': 'pt-BR',
  'pt-BR': 'pt-BR',
  'de': 'de-DE',
  'de-DE': 'de-DE',
  'zh': 'zh-TW',
  'zh-TW': 'zh-TW'
}