import { useEffect, useState } from 'react'
import { List, Row, Space, Typography } from 'antd'
import { useIntl } from 'react-intl'

interface MacRegOnboardedVariables {
  MAC_ADDRESS: string;
  IDENTITY_ID: string;
  IDENTITY_GROUP_ID: string;
  NETWORKS: string;
}
export function MacRegOnboarded(props: { data: MacRegOnboardedVariables }) {
  const { $t } = useIntl()
  const { Text, Link } = Typography
  const { data } = props
  const networkList = data.NETWORKS.split(', ')
  const [selectedSsid, setSelectedSsid] = useState<string>('')
  const macAddress = data.MAC_ADDRESS;
  useEffect(() => {
    if (networkList.length === 1) {
      setSelectedSsid(networkList[0])
    }
  }, [])
  return (

    <Space direction='vertical' size='large'>

      <Text>
        {$t({ defaultMessage: 'The device with MAC address ' })} <Text strong data-testid='macAdd'>{macAddress} </Text>
        {$t({ defaultMessage: ' is now allowed to connect to the following network:' })}
      </Text>
      {selectedSsid &&
        <Space direction='vertical' size='large'>
          <Link onClick={() => setSelectedSsid('')}> {selectedSsid}</Link>
        </Space>
      }
      {(networkList.length > 1 && selectedSsid === '') &&
        <List bordered dataSource={networkList} renderItem={
          (ssid) => (
            (ssid && ssid.trim().length > 0) && <List.Item>
              <Row justify='space-between' style={{ width: '100%' }}>
                <Space align='baseline'>
                  <Link onClick={() => setSelectedSsid(ssid)}>{ssid}</Link>
                </Space>
              </Row>
            </List.Item>
          )} />
      }

    </Space>
  )
}
