import {ActionProps}                from '../types'
import {DisplayMessageActionConfig} from '../types/DisplayMessageActionConfig'
import React                        from 'react'
import {Space}                      from 'antd';

const DisplayMessageAction: React.FC<ActionProps<DisplayMessageActionConfig>> = ({config}) => {
  return (
    <Space direction="vertical" align="center">
      <span dangerouslySetInnerHTML={{__html: config.messageHtml}}/>
    </Space>
  )
}

export default DisplayMessageAction