import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState}                  from '../store'

type AuthState = {
  workflowId: string | null
  token: string | null
}

const slice = createSlice({
  name: 'auth',
  initialState: { workflowId: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { workflowId, token },
      }: PayloadAction<{ workflowId?: string; token?: string }>,
    ) => {
      if (workflowId) {
        state.workflowId = workflowId
      }
      if (token) {
        state.token = token
      }
    },
    loggedOut (state: AuthState) {
      window.document.location.reload()
    }
  },
})

export const { setCredentials, loggedOut } = slice.actions

export default slice.reducer

export const selectCurrentWorkflowId = (state: RootState) => state.auth.workflowId
export const selectToken = (state: RootState) => state.auth.token

