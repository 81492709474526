import { PhoneNumberType, PhoneNumberUtil } from "google-libphonenumber"

const basicPhoneNumberRegExp = new RegExp (/^\+[1-9]\d{1,14}$/)

export function phoneRegExp (value: string, errorMessage:string) {
  if (value && !basicPhoneNumberRegExp.test(value)) {
    return Promise.reject(errorMessage)
  }

  if (value && !validateMobileNumber(value)){
    return Promise.reject(errorMessage)
  }
  return Promise.resolve()
}


function validateMobileNumber (phoneNumber: string) {
  const parsedPhone = parsePhoneNumber(phoneNumber)
  const phoneNumberUtil = PhoneNumberUtil.getInstance()

  if (parsedPhone === undefined) {
    return false
  } else {
    const { number, type } = parsedPhone
    if (!phoneNumberUtil.isValidNumber(number)
      || (type !== PhoneNumberType.MOBILE && type !== PhoneNumberType.FIXED_LINE_OR_MOBILE)) {
      return false
    }
  }
  return true
}

function parsePhoneNumber (phoneNumber: string): {
  number: libphonenumber.PhoneNumber,
  type: PhoneNumberType
} | undefined {
  const phoneNumberUtil = PhoneNumberUtil.getInstance()
  let number
  let phoneNumberType
  try {
    number = phoneNumberUtil.parse(phoneNumber, '')
    phoneNumberType = phoneNumberUtil.getNumberType(number)
  } catch (e) {
    return
  }
  if (!number) {
    return
  }

  return { number, type: phoneNumberType }
}