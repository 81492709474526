import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery, FetchBaseQueryError
}                  from '@reduxjs/toolkit/query/react';
import {RootState} from '../store';
import {loggedOut} from '../store/authSlice';
import { setErrorMessage } from '../store/errorSlice';


const baseQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const {getState} = api;
  const state = getState() as RootState;
  const workflowId = state.auth.workflowId as string

  return fetchBaseQuery({
    baseUrl : new URL(`/deviceEnrollments/workflows/${workflowId}`, location.origin).href,
    prepareHeaders: (headers, {getState}) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      headers.set('Content-Type', 'application/json')
      return headers
    }
  })(args, api, extraOptions)
};

const baseQueryWithAuthReload: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error) {
    switch (result.error.status) {
      case 401:
        api.dispatch(loggedOut())
        break
      case 403:
        api.dispatch(setErrorMessage('Incorrect path variables'))
        break
      case 500:
      case 503:
        api.dispatch(setErrorMessage(`Error: ${JSON.stringify(result.error.data || 'UNKNOWN')}`))
    }
  }
  return result
}

export const baseApi = createApi({
  baseQuery: baseQueryWithAuthReload,
  reducerPath: 'baseApi',
  keepUnusedDataFor: 5 * 60,
  refetchOnMountOrArgChange: 30 * 60,
  endpoints: () => ({})
});
