import React, { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useLoginToWorkflowMutation } from './api/enrollement';
import { setCredentials } from './store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import EnrollmentUI from './EnrollmentUI';
import { IntlProvider } from 'react-intl';
import { browserSupportedLocales } from './types';
import { RootState } from './store';
import ErrorPage from './ErrorPage';

const App: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const accessToken = searchParams.get('auth')?.replace(/\s/g, '+')
  const workflowId = searchParams.get('workflow')
  const enrollmentId = sessionStorage.getItem('enrollmentId')
  const errorMessage = useSelector((state: RootState) => state.error.errorMessage);
  const [locale, setLocale] = useState('')
  const [messages, setMessages] = useState()

  const [login, {isLoading, isSuccess, isError, data, error}] = useLoginToWorkflowMutation()

  useEffect(() => {
    if (workflowId && accessToken) {
      dispatch(setCredentials({workflowId}))
      login({accessToken, enrollmentId})
    }
  }, [])

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setCredentials({token: data.token}))
      sessionStorage.setItem('enrollmentId', data.enrollment.id)
    }
    if (isError) {
      console.error('Error loading workflow: ', error)
    }
  }, [isSuccess, isError])

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const locales = navigator.languages
        const locale = locales.find(locale =>
          browserSupportedLocales[locale as keyof typeof browserSupportedLocales]) || 'en-US'
        const messages = await import(`./locales/compiled/${locale}.json`)
        setMessages(messages.default)
        setLocale(locale)
      } catch (error) {
        console.error('Error loading messages: ', error)
      }
    }
    loadMessages()
  }, [])

  const localeOnError = (error: { code: string; }) => {
    if (error.code === 'MISSING_TRANSLATION') {
      // suppress missing translation error
      return
    }
    console.log('Error loading locale: ', error)
  }

  return (
    <IntlProvider defaultLocale='en' locale={locale} messages={messages} onError={localeOnError}>
      <div className="App" style={{height: '100vh', backgroundColor: 'lightgray'}}>
        <Row justify="center" style={{backgroundColor: 'lightgray'}}>
          <Col sm={8} xs={24} style={{height: '100vh'}}>
            {!errorMessage ? <EnrollmentUI/> : <ErrorPage message={errorMessage}/>}
          </Col>
        </Row>
        <Spin spinning={isLoading} fullscreen/>
      </div>
    </IntlProvider>
  )
}

export default App
