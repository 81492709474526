import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    errorMessage: '',
  },
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setErrorMessage } = errorSlice.actions;

export default errorSlice.reducer;