import { CancelCircleSolid, PoweredBy } from "./icons";
import styled from 'styled-components';
import { Content, Footer } from "antd/es/layout/layout";
import { Layout, Space, Typography } from "antd";
import { ErrorResponse, useRouteError } from "react-router-dom";

const ErrorImg = styled(props => <CancelCircleSolid {...props} />)`
  height: 32px;
  width: 32px;
`
const ErrorWrapper = styled.div`
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  word-break: break-all
`
const ErrorPage = ({ message = '' }) => {
  const error = useRouteError() as ErrorResponse;
  console.log(error)

  return (
    <Layout style={{ backgroundColor: 'white', minHeight: '100vh', textAlign: 'center' }}>
      <Content style={{ margin: '28px 16px' }}>
        <ErrorImg />
        <Typography.Title level={3}>An error occurred</Typography.Title>
        <Typography.Text>Please try again. If the problem persists, contact your network administrator.</Typography.Text>
        <ErrorWrapper>
          {message || error?.data}
        </ErrorWrapper>
      </Content>
      <Footer style={{ display: 'flex', justifyContent: 'end' }}>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};

export default ErrorPage;
